@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.pcnone {
  display: none;
}

@media screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

a:link {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:hover,
a:active {
  color: #fff;
}

body {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  counter-reset: number 0;
  line-height: 2em;
  letter-spacing: 0.015em;
  color: #fff;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  background: #000;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .inner {
    padding: 0;
  }
}

.inner03 {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .inner03 {
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .inner03 {
    padding: 0 10px;
  }
}

.inner04 {
  max-width: 1400px;
  margin: 0 auto;
}

@media screen and (max-width: 1400px) {
  .inner04 {
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .inner04 {
    padding: 0 10px;
  }
}

.inner02 {
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .inner02 {
    padding: 0;
  }
}

.inner2 {
  margin: 0 auto;
  max-width: 1500px;
}

@media screen and (max-width: 1400px) {
  .inner2 {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1200px) {
  .inner2 {
    padding: 0;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media screen and (max-width: 834px) {
  header#global_header {
    padding-top: 60px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

#header .inner {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  #header .inner {
    padding: 25px 10px;
  }
}

@media screen and (max-width: 834px) {
  #header .inner {
    display: block;
    padding: 0;
  }
}

#header .inner .flxL {
  width: 30%;
}

@media screen and (max-width: 834px) {
  #header .inner .flxL #siteID {
    position: fixed;
    top: 0;
    margin: 0 !important;
    right: 10px;
    z-index: 12;
    height: 60px;
    width: 25%;
  }
}

#header .inner .flxL #siteID a {
  text-align: left;
  display: block;
}

@media screen and (max-width: 834px) {
  #header .inner .flxL #siteID a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    height: 60px;
  }
}

#header .inner .flxR {
  width: 68%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  #header .inner .flxR {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 10px;
    background: rgba(25, 25, 25, 0.8);
    z-index: 12;
  }
}

#header .inner .flxR .tel2 {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 834px) {
  #header .inner .flxR .tel2 {
    background: -webkit-gradient(linear, left top, left bottom, from(#08c), to(#06a));
    background: -webkit-linear-gradient(#08c, #06a);
    background: linear-gradient(#08c, #06a);
    padding: 0 10px;
    border-radius: 5px;
    width: 48%;
  }
}

@media screen and (max-width: 640px) {
  #header .inner .flxR .tel2 {
    padding: 0;
  }
}

#header .inner .flxR .tel2 i {
  margin-right: 7px;
}

#header .inner .flxR .tel2 a {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 1;
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 20px;
}

#header .inner .flxR .cont {
  margin-left: 15px;
}

@media screen and (max-width: 834px) {
  #header .inner .flxR .cont {
    width: 48%;
  }
}

#header .inner .flxR .cont a {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  background: #580000;
  height: 100%;
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
}

@media screen and (max-width: 834px) {
  #header .inner .flxR .cont a {
    border-radius: 5px;
  }
}

#header .inner .flxR .cont a i {
  margin-right: 7px;
}

#hednav {
  z-index: 10;
  margin: 0 auto;
  width: 100%;
  background: url(../images/common/pt_3.png);
}

@media screen and (max-width: 1200px) {
  #hednav {
    padding: 0 10px;
  }
}

#hednav .navwrap {
  max-width: 1200px;
  margin: 0 auto;
  z-index: 10;
}

@media screen and (max-width: 834px) {
  #hednav .navwrap {
    max-width: auto;
    margin: 0;
  }
}

#hednav .navwrap #nav_global {
  position: relative;
}

#hednav .navwrap #nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul {
    max-width: 100%;
  }
}

#hednav .navwrap #nav_global ul li {
  position: relative;
}

@media screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul li {
    border-left: 0;
  }
}

#hednav .navwrap #nav_global ul li a {
  padding: 15px 10px;
  display: block;
  color: #fff !important;
  font-weight: 700;
  line-height: 1;
  font-size: 18px;
  font-size: 1.125vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #nav_global ul li a {
    font-size: 18px;
  }
}

@media screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 640px) {
  #hednav .navwrap #nav_global ul li a {
    font-size: 16px;
  }
}

#hednav .navwrap #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#hednav .navwrap #nav_global ul li a span {
  margin-top: 7px;
  display: block;
  font-size: 14px;
  font-size: 0.875vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #nav_global ul li a span {
    font-size: 14px;
  }
}

@media screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul li a span {
    font-size: 13px;
  }
}

@media screen and (max-width: 640px) {
  #hednav .navwrap #nav_global ul li a span {
    font-size: 13px;
  }
}

#hednav .navwrap #nav_global ul li ul {
  display: block;
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 834px) {
  #hednav .navwrap #nav_global ul li ul {
    display: none !important;
  }
}

#hednav .navwrap #nav_global ul li ul li {
  border: 0;
}

#hednav .navwrap #nav_global ul li ul li a {
  padding: 20px 10px;
  background: rgba(255, 255, 255, 0.5);
}

#hednav .navwrap #nav_global ul li ul li:last-child {
  border-right: 0;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 10px;
    top: 11px;
    color: #fff;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 11px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #000;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 60px;
    width: 100%;
    background: rgba(25, 25, 25, 0.8);
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    padding: 0;
    display: block !important;
    background: rgba(25, 25, 25, 0.8);
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #95e1d7;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    background: #95e1d7;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ccc;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 20px;
    line-height: 1;
    height: 100%;
    font-weight: 400;
    font-size: 0.8rem !important;
  }
  #hednav .wrap .navwrap #nav_global ul li a span {
    display: none;
  }
  #hednav .wrap .navwrap #nav_global ul li a::before {
    margin-right: 10px;
    z-index: 1;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 15px;
    font-family: "Font Awesome 5 pro";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f015";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(2) a::before {
    content: "\f823";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(3) a::before {
    content: "\f7a1";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(4) a::before {
    content: "\f004";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(5) a::before {
    content: "\f733";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(6) a::before {
    content: "\f3c5";
  }
  #hednav .wrap .navwrap #nav_global ul li:nth-child(7) a::before {
    content: "\f3c5";
  }
}

@media screen and (max-width: 640px) {
  #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
  }
  #hed_info .store1 {
    width: 50%;
  }
  #hed_info .store1 p {
    background: #93c2d8;
  }
  #hed_info .store2 {
    width: 50%;
  }
  #hed_info .store2 p {
    background: #a0c6d8;
  }
  #hed_info .store3 {
    width: 100% !important;
  }
  #hed_info .store3 p {
    background: #93c2d8;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

#mainArea h1 {
  max-width: 1200px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  z-index: 1;
  line-height: 1.4em;
  font-size: 90px;
  font-size: 5.625vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea h1 {
    font-size: 90px;
  }
}

@media screen and (max-width: 834px) {
  #mainArea h1 {
    font-size: 80px;
  }
}

@media screen and (max-width: 640px) {
  #mainArea h1 {
    font-size: 40px;
  }
}

#mainArea h1 span {
  display: block;
  font-size: 75px;
  font-size: 4.6875vw;
}

@media only screen and (min-width: 1600px) {
  #mainArea h1 span {
    font-size: 75px;
  }
}

@media screen and (max-width: 834px) {
  #mainArea h1 span {
    font-size: 70px;
  }
}

@media screen and (max-width: 640px) {
  #mainArea h1 span {
    font-size: 40px;
  }
}

#mainArea h1 img {
  width: 90% !important;
}

@media screen and (max-width: 834px) {
  #mainArea h1 img {
    width: 65% !important;
  }
}

#mainArea img {
  width: auto !important;
  height: auto;
  max-width: inherit;
}

@media screen and (max-width: 834px) {
  #mainArea img {
    max-width: 100%;
  }
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
}

@media screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  background: rgba(79, 40, 2, 0.2);
  letter-spacing: 0.1em;
  color: #fff;
  font-weight: 700;
  line-height: 1.7em;
  z-index: 1;
  position: relative;
  text-align: center;
  padding: 200px 0;
  display: block;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1000px) {
  #local-keyvisual h1 {
    padding: 150px 10px;
  }
}

@media screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  #local-keyvisual h1 {
    padding: 100px 10px;
  }
}

#local-keyvisual h1:empty {
  display: none;
}

#local-keyvisual h1 span {
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 span {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  #local-keyvisual h1 span {
    font-size: 20px;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 50px 0;
}

@media screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #fff;
}

.mean-bar {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.mean-container .mean-nav ul li a.mean-expand {
  width: 32px !important;
  height: 32px !important;
}

.mean-container .mean-nav ul li li a {
  width: 100% !important;
}

.mean-container a.meanmenu-reveal {
  left: 0 !important;
  right: inherit !important;
  width: 22px !important;
  height: 22px !important;
  padding: 13px !important;
}

.mean-container .mean-bar {
  background: #7bbb41 !important;
  position: fixed !important;
  z-index: 8 !important;
  min-height: 44px;
}

.mean-container .mean-nav {
  margin-top: 44px !important;
  background: #7bbb41 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
}

footer#global_footer {
  min-width: 100%;
  background: url(../images/common/pt_3.png);
}

@media screen and (max-width: 834px) {
  footer#global_footer {
    padding-bottom: 60px;
  }
}

#footer_inner {
  padding: 60px 0 30px;
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
}

@media screen and (max-width: 1000px) {
  #footer_inner {
    padding: 60px 20px 30px;
  }
}

@media screen and (max-width: 480px) {
  #footer_inner {
    padding: 60px 10px 30px;
  }
}

#footer_inner .subnav {
  position: relative;
}

#footer_inner .subnav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  #footer_inner .subnav ul {
    max-width: 100%;
  }
}

#footer_inner .subnav ul li {
  position: relative;
}

@media screen and (max-width: 834px) {
  #footer_inner .subnav ul li {
    border-left: 0;
  }
}

#footer_inner .subnav ul li a {
  padding: 15px 10px;
  display: block;
  color: #fff !important;
  font-weight: 700;
  line-height: 1;
  font-size: 18px;
  font-size: 1.125vw;
}

@media only screen and (min-width: 1600px) {
  #footer_inner .subnav ul li a {
    font-size: 18px;
  }
}

@media screen and (max-width: 834px) {
  #footer_inner .subnav ul li a {
    font-size: 16px;
  }
}

@media screen and (max-width: 640px) {
  #footer_inner .subnav ul li a {
    font-size: 16px;
  }
}

#footer_inner .subnav ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#footer_inner .subnav ul li a span {
  margin-top: 7px;
  display: block;
  font-size: 14px;
  font-size: 0.875vw;
}

@media only screen and (min-width: 1600px) {
  #footer_inner .subnav ul li a span {
    font-size: 14px;
  }
}

@media screen and (max-width: 834px) {
  #footer_inner .subnav ul li a span {
    font-size: 13px;
  }
}

@media screen and (max-width: 640px) {
  #footer_inner .subnav ul li a span {
    font-size: 13px;
  }
}

#footer_inner small {
  display: block;
  font-size: 16px;
  margin-top: 30px;
  color: #fff;
  line-height: 50px;
}

#footer_inner small a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

#footer_inner small a:hover {
  opacity: 0.6;
}

/* TOP */
.bg100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bg01 {
  background: url(../images/common/img_1.png) 100% bottom no-repeat, url(../images/common/pt_1.png) top center;
  padding: 120px 0;
}

@media screen and (max-width: 834px) {
  .bg01 {
    padding: 80px 0;
    background: none;
    background: url(../images/common/pt_1.png) top center;
  }
}

@media screen and (max-width: 480px) {
  .bg01 {
    padding: 60px 0;
  }
}

@media screen and (max-width: 1200px) {
  .bg01 .inner02 {
    padding: 0 10px;
  }
}

.bg02 {
  background: url(../images/common/pt_1.png) top center;
  padding: 80px 0;
}

@media screen and (max-width: 834px) {
  .bg02 {
    padding: 60px 0;
  }
}

@media screen and (max-width: 480px) {
  .bg02 {
    padding: 40px 0;
  }
}

.bg03 {
  background: url(../images/common/cont_bg.jpg) top center;
  padding: 80px 0;
  background-size: cover;
}

@media screen and (max-width: 834px) {
  .bg03 {
    padding: 60px 0;
  }
}

@media screen and (max-width: 480px) {
  .bg03 {
    padding: 40px 0;
  }
}

.bg03 .waku {
  background: rgba(0, 0, 0, 0.7);
  padding: 50px 0;
}

.btn01 a {
  color: #fff;
  font-size: 20px;
  display: block;
  border: 1px solid #999;
  display: block;
  width: 260px;
  padding: 10px;
  position: relative;
  transition: .3s;
}

.btn01 a:hover {
  background: #ad0000;
}

.btn01 a::after {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  height: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
}

@media screen and (max-width: 834px) {
  .btn01 a {
    width: 100%;
  }
}

.img_txt {
  align-items: center;
}

.img_txt .leftArea {
  width: 62.857%;
}

@media screen and (max-width: 1000px) {
  .img_txt .leftArea {
    width: 55%;
  }
}

@media screen and (max-width: 834px) {
  .img_txt .leftArea {
    width: 100%;
    margin-bottom: 40px;
  }
}

.img_txt .rightArea {
  width: 25%;
  padding: 45px 30px;
  border: 2px solid #fff;
}

.img_txt .rightArea .btn01 a {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .img_txt .rightArea {
    margin-right: 0;
  }
}

@media screen and (max-width: 1200px) {
  .img_txt .rightArea {
    width: 32%;
  }
}

@media screen and (max-width: 1000px) {
  .img_txt .rightArea {
    width: 40%;
  }
}

@media screen and (max-width: 834px) {
  .img_txt .rightArea {
    width: 100%;
  }
}

.img_txt.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1400px) {
  .img_txt.reverse .rightArea {
    margin: 0;
  }
}

.img_txt .leftArea02 {
  width: 60%;
}

.img_txt .rightArea02 {
  width: 38%;
}

@media screen and (max-width: 640px) {
  .img_txt02 {
    flex-direction: column-reverse;
  }
}

.img_txt02 .leftArea {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .img_txt02 .leftArea {
    width: 100%;
  }
}

.img_txt02 .rightArea {
  width: 38%;
}

@media screen and (max-width: 640px) {
  .img_txt02 .rightArea {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .img_txt02 .rightArea {
    margin-bottom: 15px;
  }
}

.img_txt02.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 640px) {
  .img_txt02.reverse {
    flex-direction: column-reverse;
  }
}

.bnr01 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bnr01 .box {
  width: 31%;
  position: relative;
}

@media screen and (max-width: 834px) {
  .bnr01 .box {
    width: 48%;
  }
}

@media screen and (max-width: 834px) {
  .bnr01 .box:nth-child(3) {
    margin-top: 30px;
    width: 100%;
  }
}

.bnr01 .box a {
  display: block;
}

.bnr01 .box a > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 8% 20%;
}

.bnr01 .box a > div .bnrinner {
  background: url(../images/common/pt_2.png);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.bnr01 .box a > div .bnrinner span {
  border: 1px solid #261e11;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

@media screen and (max-width: 640px) {
  .bnr01 .box a > div .bnrinner span {
    font-size: 16px;
  }
}

.news {
  z-index: 1;
  position: relative;
  height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
}

@media screen and (max-width: 834px) {
  .news {
    height: 450px;
  }
}

.news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.news dl dt, .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.evenly .leftArea {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .evenly .leftArea {
    width: 100%;
    margin-bottom: 40px;
  }
}

.evenly .rightArea {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .evenly .rightArea {
    width: 100%;
  }
}

.sns_instagram .sns_list {
  border: none !important;
  flex-direction: row !important;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sns_instagram .sns_list > div {
  border: none !important;
  width: 32% !important;
  margin-bottom: 2%;
}

.sns_instagram .sns_list .sns_text {
  display: none;
}

.sns_instagram .sns_list .sns_photo {
  width: 100% !important;
  padding: 0 !important;
}

@media screen and (max-width: 1200px) {
  .contactbg .inner {
    padding: 0 10px;
  }
}

.contactbg ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .contactbg ul {
    display: block;
  }
}

.contactbg ul li {
  float: none !important;
  padding: 6% 0;
  width: 48%;
  background: rgba(85, 72, 53, 0.7);
}

@media screen and (max-width: 834px) {
  .contactbg ul li {
    width: 100%;
    margin-bottom: 30px;
  }
}

.contactbg ul li h2 {
  text-align: center;
  padding: 25px 10px;
  background: #580000;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .contactbg ul li h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 834px) {
  .contactbg ul li h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .contactbg ul li h2 {
    font-size: 18px;
  }
}

.contactbg ul li article div {
  padding: 6% 0 0;
  text-align: center;
}

/* title */
.title01 h1 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 32px;
  color: #fff;
  line-height: 1.5;
}

.title01 h1:first-letter {
  color: #ad0000;
}

@media screen and (max-width: 834px) {
  .title01 h1 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title01 h1 {
    font-size: 22px;
  }
}

.title01 h2 {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title01 h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title01 h2 {
    font-size: 22px;
  }
}

.title01 h2 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title01 h2 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title01 h2 span {
    font-size: 16px;
  }
}

.title01 h2:first-letter {
  color: #ad0000;
}

.title01 h3 {
  font-size: 28px;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 834px) {
  .title01 h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .title01 h3 {
    font-size: 20px;
  }
}

.title02 h1 {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title02 h1 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title02 h1 {
    font-size: 22px;
  }
}

.title02 h1 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title02 h1 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title02 h1 span {
    font-size: 16px;
  }
}

.title02 h1:first-letter {
  color: #ad0000;
}

.title02 h1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 60%;
  max-width: 170px;
  height: 1px;
  margin: 0 auto;
  text-align: center;
  background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.35) 40%, rgba(255, 255, 255, 0.35) 60%, transparent);
  background-image: linear-gradient(left, transparent, rgba(255, 255, 255, 0.35) 40%, rgba(255, 255, 255, 0.35) 60%, transparent);
  background-position: center;
  background-repeat: no-repeat;
}

.title02 h2 {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 28px;
  color: #fff;
  line-height: 1.5;
  padding-bottom: 10px;
  position: relative;
}

.title02 h2:first-letter {
  color: #ad0000;
}

@media screen and (max-width: 834px) {
  .title02 h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title02 h2 {
    font-size: 22px;
  }
}

.title02 h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  text-align: center;
  background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, transparent);
  background-image: linear-gradient(left, transparent, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 80%, transparent);
  background-position: center;
  background-repeat: no-repeat;
}

.title02 h3 {
  font-size: 28px;
  letter-spacing: 0.1em;
  background: #950000;
  padding: 0 0 0 15px;
}

@media screen and (max-width: 834px) {
  .title02 h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .title02 h3 {
    font-size: 20px;
  }
}

.title03 h2 {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title03 h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title03 h2 {
    font-size: 22px;
  }
}

.title03 h2 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title03 h2 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title03 h2 span {
    font-size: 16px;
  }
}

.title03 h3 {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title03 h3 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .title03 h3 {
    font-size: 22px;
  }
}

.title03 h3 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title03 h3 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title03 h3 span {
    font-size: 16px;
  }
}

.title04 h2 {
  font-size: 25px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title04 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title04 h2 {
    font-size: 18px;
  }
}

.title04 h2 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title04 h2 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title04 h2 span {
    font-size: 16px;
  }
}

.title04 h3 {
  font-size: 25px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title04 h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title04 h3 {
    font-size: 18px;
  }
}

.title04 h3 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .title04 h3 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .title04 h3 span {
    font-size: 16px;
  }
}

.title05 h2 {
  font-size: 25px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title05 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title05 h2 {
    font-size: 18px;
  }
}

.title05 h3 {
  font-size: 25px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .title05 h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .title05 h3 {
    font-size: 18px;
  }
}

.midashi h1 {
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi h1 {
    font-size: 20px;
  }
}

.midashi h2 {
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.midashi h3 {
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi h3 {
    font-size: 20px;
  }
}

.midashi2 {
  padding-bottom: 2px;
  border-bottom: 1px solid #fff;
}

.midashi2 h2 {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
  color: #fff;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .midashi2 h2 {
    line-height: 1.7em;
  }
}

.midashi2 h3 {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
  color: #fff;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 834px) {
  .midashi2 h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 640px) {
  .midashi2 h3 {
    line-height: 1.7em;
  }
}

.midashi3 h2 {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  color: #333;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 834px) {
  .midashi3 h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 30px;
    line-height: 1.7em;
  }
}

.midashi3 h2:first-letter {
  color: #ad0000;
}

.midashi3 h3 {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  color: #333;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.5em;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h3 {
    font-size: 40px;
  }
}

@media screen and (max-width: 834px) {
  .midashi3 h3 {
    font-size: 35px;
  }
}

@media screen and (max-width: 640px) {
  .midashi3 h3 {
    font-size: 30px;
    line-height: 1.7em;
  }
}

.midashi3 h3:first-letter {
  color: #ad0000;
}

.midashi4 h2 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.midashi4 h3 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi4 h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi4 h3 {
    font-size: 20px;
  }
}

.midashi4 h4 {
  color: #b91e23;
  font-weight: 700;
  line-height: 1.7em;
  display: block;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .midashi4 h4 {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .midashi4 h4 {
    font-size: 20px;
  }
}

.midashi5 h2 {
  font-size: 32px;
  line-height: 1.4;
  position: relative;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 834px) {
  .midashi5 h2 {
    font-size: 26px;
  }
}

@media screen and (max-width: 480px) {
  .midashi5 h2 {
    font-size: 22px;
  }
}

.midashi5 h2 span {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .midashi5 h2 span {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .midashi5 h2 span {
    font-size: 16px;
  }
}

.midashi5 h2:first-letter {
  color: #ad0000;
}

.midashi5 h2::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 60%;
  max-width: 170px;
  height: 1px;
  margin: 0 auto;
  text-align: center;
  background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.35) 40%, rgba(255, 255, 255, 0.35) 60%, transparent);
  background-image: linear-gradient(left, transparent, rgba(255, 255, 255, 0.35) 40%, rgba(255, 255, 255, 0.35) 60%, transparent);
  background-position: center;
  background-repeat: no-repeat;
}

.concept_01 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .concept_01 {
    display: block;
  }
}

.concept_01:nth-child(odd) {
  flex-direction: row-reverse;
}

.concept_01 .title02 {
  width: 100%;
}

.concept_01 .leftArea {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .concept_01 .leftArea {
    width: 100%;
    margin-bottom: 30px;
  }
}

.concept_01 .leftArea .txt {
  line-height: 2em;
  z-index: 1;
}

.concept_01 .rightArea {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .concept_01 .rightArea {
    width: 100%;
  }
}

.waku {
  padding: 40px;
  color: #fff;
  border: double 7px #b91e23;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .waku {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .waku {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .waku {
    font-size: 20px;
    padding: 0;
  }
  .waku a {
    display: block;
    padding: 20px;
  }
}

.fomewrap {
  background: #f7f7f7;
  padding: 50px;
  color: #000;
}

.submit {
  margin: 20px auto 70px;
}

#form_calendar {
  background: #f7f7f7;
  padding: 50px 50px 0;
  color: #000;
}

#form_calendar .reserv {
  margin: 0;
}

.waku2 {
  background: #333;
  border: 3px solid #fc3;
  padding: 50px;
}

@media screen and (max-width: 640px) {
  .waku2 {
    padding: 30px;
  }
}

.waku2 tr {
  padding: 10px !important;
}

.waku2 td {
  border: 0 !important;
}

.waku2 td:first-child {
  width: 15% !important;
}

@media screen and (max-width: 640px) {
  .waku2 td:first-child {
    width: 100% !important;
  }
}

.waku2 td:first-child div {
  background-color: #555;
  padding: 10px !important;
}

.waku2 .ttl {
  color: #fc3;
  font-weight: bold;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .waku2 .ttl {
    font-size: 30px;
  }
}

@media screen and (max-width: 834px) {
  .waku2 .ttl {
    font-size: 25px;
  }
}

@media screen and (max-width: 640px) {
  .waku2 .ttl {
    font-size: 20px;
  }
}

.waku2 .ttl div {
  background-color: inherit !important;
}

.gallerywrap {
  margin-top: 30px !important;
  position: static !important;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  width: auto !important;
  margin-left: -3.44828% !important;
}

@media screen and (max-width: 640px) {
  .gallerywrap {
    margin-left: 0 !important;
    justify-content: space-between;
  }
}

.gallerywrap .box {
  text-align: center;
  float: none !important;
  height: auto !important;
  width: 21.77419% !important;
  margin-right: 0 !important;
  margin-left: 3.22581% !important;
  margin-bottom: 3.22581% !important;
}

@media screen and (max-width: 640px) {
  .gallerywrap .box {
    width: 48% !important;
    margin-left: 0 !important;
  }
}

.gallerywrap .box img {
  width: 100% !important;
}

.gallerywrap .gallarttxt {
  background: #f7f7f7;
  padding: 20px;
  border: 1px solid #ccc;
  display: block;
  color: #000;
}

@media screen and (max-width: 640px) {
  .price_list {
    flex-direction: column-reverse;
  }
}

.price_list .leftArea {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .price_list .leftArea {
    width: 100%;
  }
}

.price_list .rightArea {
  width: 38%;
}

@media screen and (max-width: 640px) {
  .price_list .rightArea {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 480px) {
  .price_list .rightArea {
    margin-bottom: 15px;
  }
}

.price dl {
  border-bottom: 1px dashed #666;
  padding: 15px 0;
  flex-wrap: wrap;
}

.price dl:first-of-type {
  padding-top: 0;
}

.price dl dt {
  background: none !important;
  border: none !important;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .price dl dt {
    width: 100% !important;
  }
}

.price dl dd {
  background: none !important;
  border: none !important;
  text-align: right;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .price dl dd {
    width: 100% !important;
  }
}

.price02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.price02 dl {
  border-bottom: 1px dashed #666;
  padding: 15px 0;
  flex-wrap: wrap;
  width: 48%;
}

@media screen and (max-width: 640px) {
  .price02 dl {
    width: 100%;
  }
}

.price02 dl dt {
  background: none !important;
  border: none !important;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .price02 dl dt {
    width: 100% !important;
  }
}

.price02 dl dd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: none !important;
  border: none !important;
  text-align: right;
  padding: 0 !important;
}

@media screen and (max-width: 480px) {
  .price02 dl dd {
    width: 100% !important;
  }
}

.shop_info dl {
  flex-wrap: wrap;
}

.shop_info dl dt {
  background: #161616 !important;
  align-items: center !important;
}

@media screen and (max-width: 480px) {
  .shop_info dl dt {
    width: 100% !important;
    border-right: 1px solid #fff !important;
  }
  .shop_info dl dt:last-of-type {
    border-bottom: none !important;
  }
}

.shop_info dl dd {
  background: #363636 !important;
}

@media screen and (max-width: 480px) {
  .shop_info dl dd {
    width: 100% !important;
    text-align: center;
  }
}

.not_page {
  font-size: 40px;
}

@media screen and (max-width: 834px) {
  .not_page {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .not_page {
    font-size: 24px;
  }
}

.imgshadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.imgshadow img {
  width: 100% !important;
}

.img100 img {
  width: 100% !important;
}

.menu2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .menu2 {
    display: block;
  }
}

.menu2 .flxL {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .menu2 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.menu2 .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.menu2 .flxR {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .menu2 .flxR {
    width: 100%;
  }
}

.menu2 .fee {
  color: #b91e23;
  font-size: 20px;
  font-weight: 700;
}

.menu3 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .menu3 {
    display: block;
  }
}

.menu3 .flxL {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .menu3 .flxL {
    width: 100%;
  }
}

.menu3 .flxR {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .menu3 .flxR {
    width: 100%;
  }
}

.menu4 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .menu4 {
    display: block;
  }
}

.menu4 .box {
  width: 30%;
}

@media screen and (max-width: 640px) {
  .menu4 .box {
    width: 100%;
  }
}

.party {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 100px 0;
  position: relative;
  padding: 70px 0;
}

@media screen and (max-width: 1000px) {
  .party {
    padding: 30px 10px;
  }
}

@media screen and (max-width: 834px) {
  .party {
    margin: 0 0 50px 0;
    padding: 30px 0;
  }
}

@media screen and (max-width: 640px) {
  .party {
    display: block;
  }
}

.party .flxL {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .party .flxL {
    width: 100%;
    margin-bottom: 50px;
  }
}

.party .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.party .flxR {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .party .flxR {
    width: 100%;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  background: url(../images/common/pt_2.png);
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  z-index: -1;
}

.mediawrap {
  margin-left: -2.43902% !important;
}

@media screen and (max-width: 640px) {
  .mediawrap {
    margin-left: 0 !important;
  }
}

.mediawrap .media {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 640px) {
  .mediawrap .media {
    display: block;
  }
}

.mediawrap .media .box {
  float: none !important;
  width: 47.56098% !important;
  margin-left: 2.43902% !important;
  margin-bottom: 30px !important;
}

@media screen and (max-width: 640px) {
  .mediawrap .media .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.mediawrap .media .box img {
  width: 100% !important;
  margin-bottom: 10px;
}

.mediawrap .media .box h3 {
  font-size: 16px;
  font-weight: bold;
  color: darkgoldenrod;
  text-align: left;
}

@media screen and (max-width: 834px) {
  .mediawrap .media .box h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 640px) {
  .mediawrap .media .box h3 {
    font-size: 17px;
    line-height: 1.7em;
  }
}

.shopinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .shopinfo {
    display: block;
  }
}

.shopinfo:nth-child(odd) {
  flex-direction: row-reverse;
}

.shopinfo .flxL {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .shopinfo .flxL {
    width: 100%;
  }
}

.shopinfo .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.shopinfo .flxR {
  width: 38%;
}

@media screen and (max-width: 640px) {
  .shopinfo .flxR {
    width: 100%;
  }
}

.mediawrap2 {
  margin-bottom: 100px;
  margin-left: -2.43902% !important;
}

@media screen and (max-width: 640px) {
  .mediawrap2 {
    margin-bottom: 50px;
    margin-left: 0 !important;
  }
}

.mediawrap2 .media2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 640px) {
  .mediawrap2 .media2 {
    display: block;
  }
}

.mediawrap2 .media2 .box {
  float: none !important;
  width: 30.89431% !important;
  margin-left: 2.43902% !important;
  margin-bottom: 30px !important;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .mediawrap2 .media2 .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.mediawrap2 .media2 .box img {
  width: 100% !important;
  margin-bottom: 10px;
}

.shopinfo2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px;
}

@media screen and (max-width: 640px) {
  .shopinfo2 {
    margin-bottom: 50px;
    display: block;
  }
}

.shopinfo2 .flxL {
  width: 70%;
}

@media screen and (max-width: 640px) {
  .shopinfo2 .flxL {
    width: 100%;
  }
}

.shopinfo2 .flxL .txt {
  line-height: 2em;
  z-index: 1;
}

.shopinfo2 .flxR {
  width: 28%;
}

@media screen and (max-width: 640px) {
  .shopinfo2 .flxR {
    width: 100%;
  }
}

.flow .ttl {
  font-size: 20px;
  width: 30%;
}

.flow .ttl span {
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
  text-align: center;
  background: #00559d;
  color: #fff;
  padding: 10px 15px;
}

@media screen and (max-width: 640px) {
  .flow .ttl span {
    display: block;
    margin-bottom: 15px;
  }
}

.flow td {
  border: 0 !important;
}

.picktxt {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 2em;
}

.picktxt ul li {
  padding: 5px 10px !important;
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 640px) {
  .comp dl {
    display: block !important;
  }
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media screen and (max-width: 640px) {
  .comp dl dt {
    width: 100% !important;
  }
}

.comp dl dd {
  padding: 15px 0 !important;
  border: 0 !important;
  background-color: inherit !important;
}

@media screen and (max-width: 640px) {
  .comp dl dd {
    padding: 0 0 15px 0 !important;
    width: 100% !important;
  }
}

.comp dl a {
  color: #b2b2b2;
}

@media screen and (max-width: 640px) {
  .comp2 dl {
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .comp2 dl dt {
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #b2b2b2;
}

.faq_q {
  border: none !important;
}

.faq_q > div {
  font-size: 16px;
  border-bottom: 1px #8cafc0 dashed;
  padding-bottom: 20px !important;
  position: relative;
  color: #8cafc0;
  font-weight: bold;
  position: relative;
  padding-left: 3em !important;
}

.faq_q > div:before {
  content: "Q";
  display: block;
  background: #8cafc0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: -9px;
}

.faq_a {
  border: none !important;
}

.faq_a > div {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  border-bottom: 1px #ccc solid;
  padding-bottom: 30px !important;
  position: relative;
  padding-left: 3em !important;
}

.faq_a > div:before {
  content: "A";
  display: block;
  background: #9fcfe6;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 600px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  position: absolute;
  left: 0px;
}

@media screen and (max-width: 640px) {
  .img100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .img100 .box {
    margin-bottom: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.modal {
  text-decoration: underline;
  color: #f7f7f7;
}

.center {
  text-align: center;
}
